import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import CTA from "../components/common/CTA"
import Hero from "../components/common/Hero/Hero"
import SVGIcon from "../components/common/SVGIcon"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import "../styles/templates/case-studies.scss"

const CaseStudies = ({ pageContext }) => {
  const { casestudy } = pageContext

  let navBgColor = casestudy.heroSection.backgroundColor
  navBgColor = navBgColor.slice(
    navBgColor.indexOf("#"),
    navBgColor.indexOf("#") + 7
  )

  return (
    <div className="casestudy-page">
      <Frame seo={casestudy.seo}>
        <header>
          <Navigation
            page="Home"
            color={navBgColor}
            dskScroll="500"
            newButton
          />
        </header>

        <Hero
          background={casestudy.heroSection.backgroundColor}
          tagName={casestudy.heroSection.tag}
          heading={casestudy.heroSection.heading.text}
          description={casestudy.heroSection.content.text}
          maxHeadingWidth="550px"
          maxDescriptionWidth="530px"
          image={casestudy.heroSection.heroImage.url}
          downloadFile={casestudy.heroSection.heroImageMob.url}
          downloadFileBtnText={casestudy.heroSection.heroCtaText}
          downloadFileName={casestudy.heroSection.headingLine1}
          videoURL={casestudy.heroSection?.tagLink}
          newButton
          lgLeft={5}
          lgRight={7}
        />

        {casestudy.card.length ? (
          <section className="benefit-cards mb100 down-to-up-1">
            <Container className="Layout-container d-flex justify-content-between wrapper">
              {casestudy.card.map(item => {
                return (
                  <Col
                    lg={4}
                    className="ben-card text-center br10"
                    style={{ background: item.color[0].hex }}
                  >
                    <p className="fw-bold number m-0">{item.text[0]}</p>
                    <p className="font-roboto p24 fw-bold m-0">
                      {item.text[1]}
                    </p>
                  </Col>
                )
              })}
            </Container>
          </section>
        ) : null}

        <section className="main-content">
          <Container className="Layout-container">
            <Row>
              <Col lg={4} className="left-col">
                {casestudy.newfield.map(item => {
                  return (
                    <Fade bottom delay={60} duration="800" distance={"20px"}>
                      <div className="mb50">
                        <p className="p24 font-roboto fw-600">
                          {item.cardName}
                        </p>
                        <div className="rich-text">
                          {parse(item.content[0].html)}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </Col>
              <Col lg={8}>
                {casestudy.components.map((field, index) => {
                  switch (field.__typename) {
                    case "SuperOps_Card": {
                      return (
                        <Fade
                          bottom
                          delay={60}
                          duration="800"
                          distance={"20px"}
                        >
                          <div className="altv1">
                            {field.title && (
                              <h4
                                className={`font-roboto fw-bold mb24 ${
                                  index ? "mt80" : ""
                                }`}
                              >
                                {field.title}
                              </h4>
                            )}

                            <div className="rich-text">
                              {parse(field.content[0].html)}
                            </div>
                          </div>
                        </Fade>
                      )
                    }
                    case "SuperOps_Testimonial": {
                      return (
                        <Fade
                          bottom
                          delay={60}
                          duration="800"
                          distance={"20px"}
                        >
                          <div className="purple-testimonial d-flex br10 mt32">
                            <div>
                              <SVGIcon
                                name="pinkQuoteSmall"
                                className="purple-quote"
                              />
                            </div>
                            <p className="p18 font-roboto fw-600 m-0">
                              {field.testimonialTitle}
                            </p>
                          </div>
                        </Fade>
                      )
                    }
                  }
                })}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mt100 down-to-up-1">
          <CTA
            data={[casestudy.ctaBoxes[0]]}
            className="Layout-container"
            newButton
            newDesign
          />
        </section>
      </Frame>
    </div>
  )
}

export default CaseStudies
